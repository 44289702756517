.sprite { background: url('src/assets/images/Anywhere_sprite.png') no-repeat top left !important; transform-origin: 0 0; margin: unset;}
.sprite.Anywhere_Deposit_Icon { background-position: -807px -263px !important;
  width: 212px !important;
  height: 204px !important;
  transform: scale(0.25);
  margin-left: 16px;
}
.sprite.Anywhere_Donate_Icon { background-position: -807px -5px !important;
  width: 248px !important;
  height: 248px !important;
  transform: scale(0.25);
  margin-left: 12px;
}
.sprite.Anywhere_MemberPayment_Icon { background-position: -5px -374px !important;
  width: 342px !important;
  height: 204px !important;
  transform: scale(0.25);
}
.sprite.search-icon { background-position: -345px -451px !important;
  width: 60px !important;
  height: 60px !important;
  transform: scale(0.5) translate(45%, 30%) !important;
  border: none;
  opacity: 0.8;
  @media (max-width: 576px) and (orientation: portrait),
  (max-height: 576px) and (orientation: landscape) {
    background-position: -357px -456px !important;
    width: 48px !important;
    height: 48px !important;
  }

}
.sprite.deposit-mid { background-position: -357px -374px !important;
  transform: scale(0.5);
  width: 82px !important;
  height: 72px !important;
}
.sprite.dollar-icon { background-position: -415px -456px !important;
  width: 48px;
  height: 48px;
  transform: scale(0.3);
  position: absolute;
  right: 0;
  top: 10px;
}
.sprite.donate-mid { background-position: -439px -374px !important;
  transform: scale(0.5);
  width: 82px !important;
  height: 72px !important;
}
.sprite.member-mid { background-position: -521px -374px !important;
  transform: scale(0.5);
  width: 82px !important;
  height: 72px !important;
}
.sprite.menu { background-position: -603px -374px !important;
  width: 64px !important;
  height: 64px !important;
  transform: scale(0.5);
}
.sprite.right-arrow { background-position: -1029px -263px !important;
  width: 10px !important;
  height: 22px !important;
  transform: scale(0.6);
}
.sprite.Anywhere_Logo_CLR { background-position: -5px -210px !important;
  transform: scale(0.25);
  width: 792px !important;
  height: 154px !important;
  margin-left: calc((100% - (792px * 0.25)) / 2);
}
.sprite.Anywhere_Logo_Full-WHT { background-position: -5px -5px !important;
  transform: scale(0.4);
  width: 792px !important;
  height: 195px !important;
  margin-left: calc((100% - (792px * 0.4)) / 2);
  max-height: 195px;
}
.sprite.BH_Logo_Color-white { background-position: -807px -530px !important;
  width: 220px !important;
  height: 43px !important;
}
.sprite.BH_Logo_Color { background-position: -807px -477px !important;
  width: 220px !important;
  height: 43px !important;
}
.sprite.Anywhere_Logo_Small{background-position: -677px -374px !important;
  width: 105px !important;
  height: 20px !important;
}
