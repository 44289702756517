$blue-color: #20ACE2;
$white-color: #ffffff;
$dark-blue: #1B3B69;
$dark-gray-color: #969696;
$gray-color: #D8D8D8;
$black-color: #404041;
$faint-gray:#e9e9e9;
$dark-gray:#585858;
$red-color:#ee5555;
$pure-black-color: #000000;
$navy-blue: #3f5efb;
