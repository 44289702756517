.sprite {
  background: url("src/assets/images/Anywhere_sprite.png") no-repeat top left !important;
  transform-origin: 0 0;
  margin: unset;
}

.sprite.Anywhere_Deposit_Icon {
  background-position: -807px -263px !important;
  width: 212px !important;
  height: 204px !important;
  transform: scale(0.25);
  margin-left: 16px;
}

.sprite.Anywhere_Donate_Icon {
  background-position: -807px -5px !important;
  width: 248px !important;
  height: 248px !important;
  transform: scale(0.25);
  margin-left: 12px;
}

.sprite.Anywhere_MemberPayment_Icon {
  background-position: -5px -374px !important;
  width: 342px !important;
  height: 204px !important;
  transform: scale(0.25);
}

.sprite.search-icon {
  background-position: -345px -451px !important;
  width: 60px !important;
  height: 60px !important;
  transform: scale(0.5) translate(45%, 30%) !important;
  border: none;
  opacity: 0.8;
}
@media (max-width: 576px) and (orientation: portrait), (max-height: 576px) and (orientation: landscape) {
  .sprite.search-icon {
    background-position: -357px -456px !important;
    width: 48px !important;
    height: 48px !important;
  }
}

.sprite.deposit-mid {
  background-position: -357px -374px !important;
  transform: scale(0.5);
  width: 82px !important;
  height: 72px !important;
}

.sprite.dollar-icon {
  background-position: -415px -456px !important;
  width: 48px;
  height: 48px;
  transform: scale(0.3);
  position: absolute;
  right: 0;
  top: 10px;
}

.sprite.donate-mid {
  background-position: -439px -374px !important;
  transform: scale(0.5);
  width: 82px !important;
  height: 72px !important;
}

.sprite.member-mid {
  background-position: -521px -374px !important;
  transform: scale(0.5);
  width: 82px !important;
  height: 72px !important;
}

.sprite.menu {
  background-position: -603px -374px !important;
  width: 64px !important;
  height: 64px !important;
  transform: scale(0.5);
}

.sprite.right-arrow {
  background-position: -1029px -263px !important;
  width: 10px !important;
  height: 22px !important;
  transform: scale(0.6);
}

.sprite.Anywhere_Logo_CLR {
  background-position: -5px -210px !important;
  transform: scale(0.25);
  width: 792px !important;
  height: 154px !important;
  margin-left: calc((100% - (792px * 0.25)) / 2);
}

.sprite.Anywhere_Logo_Full-WHT {
  background-position: -5px -5px !important;
  transform: scale(0.4);
  width: 792px !important;
  height: 195px !important;
  margin-left: calc((100% - (792px * 0.4)) / 2);
  max-height: 195px;
}

.sprite.BH_Logo_Color-white {
  background-position: -807px -530px !important;
  width: 220px !important;
  height: 43px !important;
}

.sprite.BH_Logo_Color {
  background-position: -807px -477px !important;
  width: 220px !important;
  height: 43px !important;
}

.sprite.Anywhere_Logo_Small {
  background-position: -677px -374px !important;
  width: 105px !important;
  height: 20px !important;
}

@font-face {
  font-family: GothamPro;
  src: url("https://cdn.billhighway.com/files/Anywhere/Fonts/GothamPro-Light.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: GothamPro;
  src: url("https://cdn.billhighway.com/files/Anywhere/Fonts/GothamPro.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: GothamPro;
  src: url("https://cdn.billhighway.com/files/Anywhere/Fonts/GothamPro-Medium.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: GothamPro;
  src: url("https://cdn.billhighway.com/files/Anywhere/Fonts/GothamPro-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
html {
  width: 100%;
  height: 100%;
  font-family: GothamPro, sans-serif;
  user-select: none;
  font-size: 16px;
}

body {
  width: 100%;
  height: 100%;
  margin: unset;
}

.blue-label {
  font-size: 14px;
  color: #20ACE2;
}

.gray-label {
  font-size: 10px;
  color: #585858;
}

input {
  box-sizing: border-box;
}

.w-100 {
  width: 100%;
}

.top-label {
  text-align: left !important;
  position: relative;
}

.is-invalid {
  border: 2px solid #ee5555 !important;
}

.select-invalid-feedback {
  color: #ee5555;
  font-size: 12px;
  font-weight: 600;
  position: unset;
  line-height: 15px;
}

input[type=text] {
  height: 50px;
  border-radius: 7px;
  font-family: GothamPro, sans-serif;
  font-size: 15px;
  font-weight: 200;
}
@media (max-width: 576px) and (orientation: portrait), (max-height: 576px) and (orientation: landscape) {
  input[type=text] {
    height: 40px;
  }
}

input[type=search] {
  height: 50px;
  border-radius: 7px;
  font-family: GothamPro, sans-serif;
  font-size: 15px;
  font-weight: 200;
}
@media (max-width: 576px) and (orientation: portrait), (max-height: 576px) and (orientation: landscape) {
  input[type=search] {
    height: 40px;
  }
}

input[type=password] {
  height: 50px;
  border-radius: 7px;
  font-family: GothamPro, sans-serif;
  font-size: 15px;
  font-weight: 200;
}
@media (max-width: 576px) and (orientation: portrait), (max-height: 576px) and (orientation: landscape) {
  input[type=password] {
    height: 40px;
  }
}

.common-submit-button {
  width: 100% !important;
  max-width: 100%;
  height: 50px;
  color: #ffffff;
  border-radius: 7px;
  border: none;
  padding-left: 10px;
  background: #20ACE2;
  font-size: 18px;
  font-family: GothamPro, sans-serif;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .common-submit-button {
    height: 40px;
  }
}

.common-submit-button-disabled {
  background: #585858;
}

.invalid-feedback {
  color: #ee5555;
  font-weight: 600;
  font-size: 12px;
  position: absolute;
  width: 80%;
  line-height: 25px;
}

.login-container .invalid-feedback {
  margin: 0 0 0 0%;
}

.float-invalid-feedback {
  margin-top: 2px;
  font-size: 12px;
  font-weight: 600;
  color: #ee5555;
  line-height: 15px;
  width: 100% !important;
}

body .selected .ui-inputtext {
  color: #ffffff !important;
  background: #20ACE2 !important;
  border: transparent !important;
}

.ui-dropdown {
  height: 40px;
  line-height: 26px;
  color: #585858 !important;
  background: unset !important;
  background-color: #404041;
  width: 100% !important;
}

body .selected .ui-dropdown {
  color: #ffffff !important;
  background: #20ACE2 !important;
  border: transparent !important;
}

body .ui-dropdown .ui-dropdown-trigger {
  color: #20ACE2 !important;
}

body .selected .ui-dropdown .ui-dropdown-trigger {
  background-color: #20ACE2 !important;
  color: #ffffff !important;
}

body .ui-dropdown .ui-dropdown-clear-icon {
  color: #585858 !important;
  font-size: 18px;
}

body .card-entry-container .ui-dropdown .ui-dropdown-trigger, .guest-container .ui-dropdown .ui-dropdown-trigger {
  background-color: transparent !important;
}
body .card-entry-container .ui-corner-all, .guest-container .ui-corner-all {
  border-radius: 7px;
}

body .invalid .ui-dropdown {
  border: 2px solid #ee5555 !important;
}

body .dropdown-select.ui-dropdown .ui-dropdown-trigger {
  border: transparent;
}

body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight {
  color: #ffffff;
  background-color: #20ACE2 !important;
}

body .radio-container .ui-radiobutton-label {
  margin: 0 0 0 0.5em;
  font-weight: 600;
  font-size: 13px;
}

.body .ui-chkbox .ui-chkbox-box {
  border-radius: 0px;
}

body .invoice-container .ui-radiobutton .ui-radiobutton-box {
  height: 16px;
  width: 16px;
  border-radius: 2% !important;
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
  background-color: #20ACE2 !important;
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
  border-color: #20ACE2 !important;
  background-color: #20ACE2 !important;
  color: #20ACE2 !important;
}

body .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: #20ACE2 !important;
  color: #ffffff !important;
}

body .ui-dialog {
  position: absolute !important;
  bottom: 0px !important;
  top: unset !important;
  max-width: 600px !important;
  box-shadow: 0 0 6px 0 #88888800 !important;
}
body .ui-dialog .ui-dialog-content {
  height: auto !important;
}

.login-container .table-row:hover, .forgot-password-container .table-row:hover, .forgot-username-container .table-row:hover {
  background-color: #D8D8D8 !important;
  cursor: pointer;
}

.card-id {
  font-size: 10px;
  color: #585858;
}

body .ui-widget {
  font-family: GothamPro, sans-serif;
  box-sizing: border-box;
}
body .ui-widget label,
body .ui-widget li {
  font-family: GothamPro, sans-serif;
}

body .change-dialog .pi {
  font-size: 2em !important;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

* {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body .ui-sidebar {
  padding: 0% !important;
  color: transparent !important;
  border: transparent !important;
}

body .ui-sidebar .ui-sidebar-close {
  color: transparent !important;
  height: 28px;
  width: 28px;
  background-size: contain;
  background-repeat: no-repeat;
}

body .ui-listbox .ui-listbox-list .ui-listbox-item {
  padding: 0% !important;
  border: 1px solid #D8D8D8 !important;
}

.common-container {
  max-width: 600px;
  margin: 0 auto;
}

.ui-float-label > textarea ~ label {
  left: 0.25em;
  top: 1.2em;
}

.ui-float-label > textarea:focus ~ label,
.ui-float-label > textarea.ui-state-filled ~ label {
  margin-left: 0 !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
}

.ui-float-label > input:focus ~ label,
.ui-float-label > input.ui-state-filled ~ label {
  margin-left: 0 !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
}

.ui-float-label > input:focus ~ label,
.ui-float-label > input.ui-state-filled ~ label,
.ui-float-label > .ui-inputwrapper-focus ~ label,
.ui-float-label > .ui-inputwrapper-filled ~ label {
  margin-left: 0 !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
}

body .ui-autocomplete .ui-autocomplete-input {
  color: #404041 !important;
}

body .ui-button.ui-button-icon-only {
  height: 50px;
  width: 50px !important;
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}
@media (max-width: 576px) and (orientation: portrait), (max-height: 576px) and (orientation: landscape) {
  body .ui-button.ui-button-icon-only {
    height: 40px;
    width: 40px !important;
  }
}

body .ui-listbox {
  min-width: 100% !important;
}

.ui-listbox .ui-listbox-list .ui-listbox-item.ui-state-highlight {
  color: #000000 !important;
  background-color: transparent !important;
}

.float-label {
  font-size: 14px;
  margin-left: 10px !important;
}

label.float-label {
  color: unset;
}

.notes-section .float-label {
  padding-left: 5px;
  padding-top: 5px;
}

.member-payment-container .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: transparent !important;
  border: 1px solid #20ACE2;
  color: #585858 !important;
}

body .member-search-container .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: #e9e9e9 !important;
  border: 1px solid #D8D8D8;
  border-radius: 50% !important;
  color: #585858 !important;
}

body .forgot-username-container .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: #e9e9e9 !important;
  border: 1px solid #D8D8D8;
  border-radius: 50% !important;
  color: #585858 !important;
}

body .forgot-password-container .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: #e9e9e9 !important;
  border: 1px solid #D8D8D8;
  border-radius: 50% !important;
  color: #585858 !important;
}

body .dialog-container .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: transparent !important;
  border-radius: 50% !important;
}

body .selected td {
  background-color: #e9e9e9 !important;
}

body .dialog-container .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: transparent !important;
  color: #404041;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

@media only screen and (orientation: landscape) {
  .deposit-container .feature-label,
.donate-main-container .feature-label,
.member-payment-container .feature-label {
    text-align: left;
  }
}
.deposit-container .feature-label,
.donate-main-container .feature-label,
.member-payment-container .feature-label {
  font-size: 14px;
}

.deposit-container .person-label,
.donate-main-container .person-label,
.member-payment-container .person-label {
  font-size: 11px;
  text-align: right;
}

.card-arrow-button {
  background-color: transparent;
  border-color: transparent;
  width: 25px;
  height: 25px;
}

.card-arrow-button:focus {
  outline: none;
}

body .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #ffffff;
}

body .ui-inputgroup .ui-inputgroup-addon {
  border-color: #D8D8D8 !important;
  border-left: 0px;
  background-color: transparent !important;
  color: #969696;
  padding: 0.429em;
  min-width: 4em !important;
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.dollar-icon-wrapper::before {
  content: "";
  background: url("./assets/images/Anywhere_sprite.png") no-repeat top left !important;
  transform-origin: 0 0;
  margin: unset;
  background-position: -415px -456px !important;
  width: 48px;
  height: 48px;
  transform: scale(0.3) translate(80%, 55%);
  position: absolute;
  right: -5px;
  top: 10px;
}
@media (max-width: 576px) and (orientation: portrait), (max-height: 576px) and (orientation: landscape) {
  .dollar-icon-wrapper::before {
    transform: scale(0.3) translate(117%, 19%);
  }
}

.search-wrapper {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border: 1px solid #D8D8D8 !important;
  margin-left: -1px;
  height: 50px;
}
@media (max-width: 576px) and (orientation: portrait), (max-height: 576px) and (orientation: landscape) {
  .search-wrapper {
    height: 40px;
  }
}

.search-icon-wrapper::before {
  content: "";
  background: url("./assets/images/Anywhere_sprite.png") no-repeat top right !important;
  transform-origin: 0 0;
  margin: unset;
  background-position: -345px -448px !important;
  width: 60px !important;
  height: 60px !important;
  transform: scale(0.5) translate(45%, 30%) !important;
  position: absolute;
  right: 0;
  border: none;
  opacity: 0.8;
}
@media (max-width: 576px) and (orientation: portrait), (max-height: 576px) and (orientation: landscape) {
  .search-icon-wrapper::before {
    background-position: -357px -456px !important;
    width: 48px !important;
    height: 48px !important;
  }
}

.ui-progress-spinner {
  margin-bottom: 8px;
}

.progress-spin {
  position: absolute;
  right: 0;
  bottom: 0;
}

.payment-total {
  font-size: 15px;
  text-align: right;
  font-weight: 600;
  color: #1B3B69;
}

.feature-name-container {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 2px dashed #ffffff;
}

.amount-input {
  width: 100%;
  max-width: 100%;
  height: 40px;
  color: #1B3B69;
  padding-right: 30px !important;
}

.notes {
  width: 100%;
  max-width: 100%;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 1em !important;
}

.change-email-link {
  line-height: 20px;
}

.ui-toast .ui-toast-message-text-content {
  margin-left: 0em !important;
  word-break: break-word;
}

.ui-toast .ui-toast-icon {
  display: none;
}

.error-toast {
  margin-bottom: 10vh;
  text-align: center;
}

body .login-container .ui-corner-all,
.forgot-username-container .ui-corner-all,
.forgot-password-container .ui-corner-all {
  border-radius: 7px;
}
body .login-container .ui-corner-right,
.forgot-username-container .ui-corner-right,
.forgot-password-container .ui-corner-right {
  border-radius: 7px;
}

.login-dropdown-panel {
  border-radius: 7px !important;
  max-width: 300px;
}

.other-dropdown-panel {
  border-radius: 7px !important;
  max-width: 590px;
}

.nonmagnawarning {
  z-index: 11004;
  left: 0px;
  top: 0px;
  height: 50px;
  transform: none;
  opacity: 1;
  width: 100%;
}

.deposit-container .ui-button,
.donate-main-container .ui-button {
  color: #20ACE2;
  width: 100% !important;
  background-color: transparent;
  border-color: #20ACE2;
}
.deposit-container .selected,
.donate-main-container .selected {
  color: #1B3B69;
  border-color: #1B3B69;
}

.transactions-container .member-search-container body .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: transparent !important;
  border: 1px solid #20ACE2;
  color: #ffffff !important;
}
.transactions-container .member-search-container td {
  height: 80px;
  border: 1px solid black !important;
}

.associate-btn {
  height: 40px;
}

@keyframes ui-progress-spinner-color {
  100%, 0% {
    stroke: #20ACE2;
  }
  40% {
    stroke: #20ACE2;
  }
  66% {
    stroke: #20ACE2;
  }
  80%, 90% {
    stroke: #20ACE2;
  }
}
.search-clear-dialog {
  position: absolute;
  right: 0;
  top: 48%;
  transform: scale(1) translate(-120%, -43%) !important;
  background: unset;
  border: none;
}
@media (max-width: 576px) and (orientation: portrait), (max-height: 576px) and (orientation: landscape) {
  .search-clear-dialog {
    transform: scale(1) translate(-80%, -45%) !important;
  }
}

.hide-icon {
  opacity: 0;
}

.search-clear {
  background: unset;
  border: none;
  outline: unset !important;
  transform: scale(1.75) translate(-29%, -125%) !important;
  position: absolute;
  right: 25px;
}
@media (max-width: 576px) and (orientation: portrait), (max-height: 576px) and (orientation: landscape) {
  .search-clear {
    transform: scale(1.5) translate(-10%, -125%) !important;
  }
}

.search-clear-float {
  background: unset;
  border: none;
  outline: unset !important;
  transform: scale(1.75) translate(40%, 40%) !important;
  position: absolute;
  right: 25px;
}
@media (max-width: 576px) and (orientation: portrait), (max-height: 576px) and (orientation: landscape) {
  .search-clear-float {
    transform: scale(1.75) translate(30%, 50%) !important;
  }
}

.logo-container {
  margin: auto !important;
}

body .ui-autocomplete .ui-autocomplete-input {
  width: 100% !important;
}

@media only screen and (orientation: landscape) and (min-width: 415px) {
  .feature-name {
    padding-left: 0% !important;
  }

  body .ui-dialog {
    top: 150px !important;
    position: fixed !important;
  }

  .settings {
    top: 3.2vh !important;
  }
}
@media only screen and (max-width: 321px) {
  .form-label {
    font-size: 11px;
  }

  .feature-name {
    font-size: 18px !important;
  }

  .payment-confirmation {
    font-size: 10px !important;
  }

  .member-payment-label {
    font-size: 11px !important;
  }

  .blue-column-header {
    font-size: 8px !important;
  }

  .change-email-link {
    font-size: 9px !important;
  }

  .member-payment-container .feature-label {
    font-size: 12px;
  }

  .member-payment-container .person-label {
    font-size: 12px;
    text-align: right;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-submit, .btn-cancel {
  background: unset;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: unset;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}